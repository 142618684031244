import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, WritableSignal, computed, effect, signal } from '@angular/core';
import { Part } from '../core/models/part';
import { PartItemComponent } from './part-item.component';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-part-grid',
    standalone: true,
    imports: [CommonModule, PartItemComponent, FormsModule],
    template: `
        <div class="product-grid home-page-product-grid">
            @if (title) {
            <div class="title">
                <strong>{{ title }}</strong>
            </div>
            }
            <div class="item-grid">
                @for (part of currentParts(); track part.id) {
                <app-part-item class="item-box" [part]="part" />
                }
            </div>
        </div>
        @if (this.pageCount() > 1) {
        <div class="pager">
            <ul>
                @if (this.currentPage() > 1) {
                <li class="previous-page"><a (click)="changePage(-1)">Previous</a></li>
                }
                <li>
                    <select [value]="currentPage()" (change)="pageDropdownChanged($event)">
                        @for (page of pages(); track page) {
                        <option [value]="page">Page {{ page }}</option>
                        }
                    </select>
                </li>
                @if (this.currentPage() < this.pageCount()) {
                <li class="next-page"><a (click)="changePage(1)">Next</a></li>
                }
            </ul>
        </div>
        }
    `,
    styles: `
    @media (min-width: 1025px) {
        ::ng-deep .home-page .product-grid .item-box:nth-child(4n + 1) {
            clear: none;
        }
    }
    `,
})
export class PartGridComponent {
    @Input()
    get parts(): Part[] {
        return this.allParts();
    }
    set parts(value: Part[]) {
        this.currentPage.set(1);
        this.allParts.set(value);
    }

    @Input() title?: string;
    @Input() pageSize: number = 9;

    allParts: WritableSignal<Part[]> = signal<Part[]>([]);

    currentPage = signal<number>(1);
    currentParts = computed(() => this.allParts().slice((this.currentPage() - 1) * this.pageSize, this.currentPage() * this.pageSize));
    pageCount = computed(() => {
        let pageCount = Math.floor(this.allParts().length / this.pageSize);
        if (this.allParts().length % this.pageSize > 0) pageCount++;
        return pageCount;
    });
    pages = computed(() => {
        const pages: number[] = [];
        for (let i = 0; i < this.pageCount(); i++) pages.push(i + 1);
        return pages;
    });

    changePage(direction: 1 | -1) {
        this.currentPage.update((page) => page + direction);
    }

    pageDropdownChanged(e: any) {
        this.currentPage.set(parseInt(e.target.value));
    }
}
